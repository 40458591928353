import { IFilterType, IInventoryFilter } from '@lamesarv-sdk/types';

export const InventorySearchPreFilter = (input: string): Record<string, IInventoryFilter> => {
  const result: Record<string, IInventoryFilter> = {};

  const filterArray = input.split('&&').map((filter) => filter.trim());

  filterArray.forEach((filter) => {
    const [facetKey, facetValue] = filter.split(':').map((item) => item.trim());
    const urlKey = facetKey.toLowerCase();
    const urlValue = facetValue
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]/g, '');

    result[facetKey] = {
      filterType: IFilterType.default,
      facetKey,
      facetValue: [facetValue],
      facetFilter: `${facetKey}: [${facetValue}]`,
      dataValue: [facetValue],
      urlKey,
      urlValue: [urlValue],
      urlFilter: urlValue,
    };
  });

  return result;
};
