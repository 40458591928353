import numberToWords from 'number-to-words';

import { IFilterListProps } from '@lamesarv-sdk/components';
import { FilterCheckboxAdvanced } from '@lamesarv-sdk/inventory';

export const FilterCheckboxSlideouts = (props: IFilterListProps) => {
  const getTitle = (amount: number) => {
    let title: string;

    if (amount === 0) {
      title = 'No Slideouts';
    } else if (amount === 1) {
      title = 'One Slideout';
    } else {
      const word = numberToWords.toWords(amount);
      title = `${word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()} Slideouts`;
    }

    return title;
  };

  const customItems = [0, ...Array(props.displayMax ?? 0).fill(0)].map((_, index) => {
    return {
      key: String(index),
      customValue: getTitle(index),
      dataValue: String(index),
      facetValue: [String(index)],
      urlValue: String(index),
      count: 0,
    };
  });

  return <FilterCheckboxAdvanced {...props} title={props.title as string} customItems={customItems} />;
};
