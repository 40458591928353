import { FieldError, get, useFormContext } from 'react-hook-form';

import * as CustomFormClasses from '../CustomForm.classes';

import { ICustomFormField } from '@lamesarv-sdk/types';

export const Text = ({ name, isRequired, index }: ICustomFormField) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const key = `fields.${index}.value`;

  const error = get(errors, key) as FieldError | null;

  return (
    <div className="my-2 px-2 w-1/2">
      <input
        type="text"
        {...register(key, { setValueAs: (v) => v || undefined })}
        placeholder={name + (isRequired ? '*' : '')}
        className={error ? CustomFormClasses.inputErrorClasses : CustomFormClasses.inputNormalClasses}
      />

      {error?.message && <div className={CustomFormClasses.errorMessageClasses}>{error.message}</div>}
    </div>
  );
};
