'use client';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, Fragment, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';

import { faCheckCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRequest } from '@lamesarv-sdk/hooks';
import { phoneRegex } from '@lamesarv-sdk/tools';
import {
  CustomFormFieldType,
  IComponentCustomForm,
  ICustomFormField,
  ICustomFormInputField,
  IFormCustomInput,
} from '@lamesarv-sdk/types';

import * as CustomFormClasses from './CustomForm.classes';
import { Email } from './fields/Email';
import { LongText } from './fields/LongText';
import { NumberField } from './fields/Number';
import { Phone } from './fields/Phone';
import { Select } from './fields/Select';
import { Text } from './fields/Text';

const fieldsMap: Record<CustomFormFieldType, FC<ICustomFormField>> = {
  [CustomFormFieldType.TEXT]: Text,
  [CustomFormFieldType.SELECT]: Select,
  [CustomFormFieldType.EMAIL]: Email,
  [CustomFormFieldType.PHONE]: Phone,
  [CustomFormFieldType.NUMBER]: NumberField,
  [CustomFormFieldType.LONG_TEXT]: LongText,
};

const fieldValidationMap: Record<CustomFormFieldType, yup.AnySchema> = {
  [CustomFormFieldType.TEXT]: yup.string(),
  [CustomFormFieldType.SELECT]: yup.string(),
  [CustomFormFieldType.EMAIL]: yup.string().email('Invalid email'),
  [CustomFormFieldType.PHONE]: yup.string().matches(phoneRegex, 'Invalid phone number'),
  [CustomFormFieldType.NUMBER]: yup.number(),
  [CustomFormFieldType.LONG_TEXT]: yup.string(),
};

// @ts-expect-error - Yup doesn't have a tuple method
yup.addMethod(yup.array, 'tuple', function (schema) {
  // @ts-expect-error - Yup doesn't have a tuple method
  if (!this.isType(schema)) yup.ValidationError();

  return yup
    .object({
      tuple: yup.array().min(schema.length).max(schema.length),
      ...Object.fromEntries(Object.entries(schema)),
    })
    .transform((_value, originalValue) => {
      // @ts-expect-error - Yup doesn't have a tuple method
      if (!this.isType(originalValue)) Yup.ValidationError();
      return {
        tuple: originalValue,
        ...Object.fromEntries(Object.entries(originalValue)),
      };
    });
});

export const CustomForm = ({ sections, formId, title, pageSlug, pageType, className }: IComponentCustomForm) => {
  const schema = yup.object().shape({
    fields: yup.array().tuple(
      sections.reduce((acc, section) => {
        section.fields.forEach((field) => {
          let valueSchema = fieldValidationMap[field.type];

          if (field.isRequired) valueSchema = valueSchema.required(`${field.name} is required`);

          const fieldSchema = yup.object().shape({
            name: yup.string().required(),
            value: valueSchema,
            activeCampaignField: yup.number(),
          });

          acc.push(fieldSchema);
        });

        return acc;
      }, [] as yup.AnySchema[]),
    ),
  });

  const { handleSubmit, formState, ...rest } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const { hasError, isLoading, sendRequest } = useRequest<undefined, IFormCustomInput>('/api/forms/submitCustom');
  const [wasSent, setWasSent] = useState(false);

  const onSubmit = async (data: {
    fields: {
      tuple: ICustomFormInputField[];
    };
  }) => {
    setWasSent(false);
    const wasSuccessful = await sendRequest({
      formId,
      pageSlug,
      pageType,
      fields: data.fields.tuple,
    });

    if (!wasSuccessful) return;

    setWasSent(true);
    rest.reset();
  };

  return (
    <FormProvider {...rest} formState={formState} handleSubmit={handleSubmit}>
      <form className={twMerge('flex flex-col gap-3', className)} onSubmit={handleSubmit(onSubmit as any)}>
        {title && <h2 className="text-2xl md:text-2xl text-sage-800 font-bold mt-7 uppercase">{title}</h2>}

        {isLoading && (
          <div
            className={
              'flex items-center w-full bg-gray-100 border-l-4 border-gray-500 text-gray-700 py-2 px-3 mb-4 mx-2 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faClock} className="w-8 mr-2" />
            <span>Sending...</span>
          </div>
        )}
        {wasSent && !hasError && (
          <div
            className={
              'flex items-center w-full bg-green-100 border-l-4 border-green-500 text-green-700 py-2 px-3 mb-4 mx-2 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faCheckCircle} className="w-8 mr-2" />
            <span>Thank you for your interest.</span>
          </div>
        )}
        {hasError && (
          <div
            className={
              'flex items-center w-full bg-red-100 border-l-4 border-red-500 text-red-700 py-2 px-3 mb-4 mx-2 shadow-lg'
            }
            role="alert"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="w-8 mr-2" />
            <span>An error has occurred, please try again later or contact us using alternative methods.</span>
          </div>
        )}

        {sections.map((section) => (
          <div className="flex flex-wrap" key={section.name}>
            {section.name && (
              <h3 className="mb-2 px-2 w-full text-lg text-sage-700 uppercase font-bold">{section.name}</h3>
            )}

            {section.fields.map((field) => {
              const Field = fieldsMap[field.type];

              return (
                <Fragment key={field.index}>
                  <Field {...field} />
                  <input type="hidden" {...rest.register(`fields.${field.index}.name`)} value={field.name} />
                  {field.activeCampaignField && (
                    <input
                      type="hidden"
                      {...rest.register(`fields.${field.index}.activeCampaignField`, {
                        valueAsNumber: true,
                      })}
                      value={field.activeCampaignField}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
        ))}

        <button
          type="submit"
          disabled={isLoading || !!Object.keys(errors).length}
          className={twMerge(
            Object.keys(errors).length
              ? CustomFormClasses.buttonInactiveClasses
              : CustomFormClasses.buttonNormalClasses,
            'self-start m-2',
          )}
        >
          Submit
        </button>
      </form>
    </FormProvider>
  );
};
