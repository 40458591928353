import { Controller, FieldError, get, useFormContext } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import * as CustomFormClasses from '../CustomForm.classes';

import { ICustomFormField } from '@lamesarv-sdk/types';

export const Phone = ({ name, isRequired, index }: ICustomFormField) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const key = `fields.${index}.value`;

  const error = get(errors, key) as FieldError | null;

  return (
    <div className="my-2 px-2 w-1/2">
      <Controller
        name={key}
        control={control}
        render={({ field }) => (
          <ReactInputMask
            type="phone"
            placeholder={name + (isRequired ? '*' : '')}
            mask="(999) 999-9999"
            className={error ? CustomFormClasses.inputErrorClasses : CustomFormClasses.inputNormalClasses}
            value={field.value || ''}
            onChange={(e) => {
              const parsedValue = e.target.value?.replace(/\D/g, '') || undefined;
              field.onChange(parsedValue);
            }}
          />
        )}
      />

      {error?.message && <div className={CustomFormClasses.errorMessageClasses}>{error.message}</div>}
    </div>
  );
};
