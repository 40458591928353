'use client';
import { inventoryItemMinWidth, maxSlidesInInventorySlider } from '@/constants/inventory';
import { SliderInventory } from '@lamesarv-sdk/inventory';
import { IComponentSliderInventory, InventoryField } from '@lamesarv-sdk/types';

import { InventoryItem } from './InventoryItem';

export const InventorySlider = (props: IComponentSliderInventory) => (
  <SliderInventory
    {...props}
    sort={`${InventoryField.order}:asc`}
    CustomInventoryItem={InventoryItem}
    minSlideWidth={inventoryItemMinWidth}
    maxSlides={maxSlidesInInventorySlider}
  />
);
