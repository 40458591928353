'use client';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Media, RichText } from '@lamesarv-sdk/components';
import { IComponentBrandsList } from '@lamesarv-sdk/types';

export enum ComponentPosition {
  left = 'left',
  center = 'center',
  right = 'right',
  fullWidth = 'full-width',
  inlineLeft = 'inline-left',
  inlineRight = 'inline-right',
}

export interface IBrand {
  title: string;
  url: string;
  imageUrl: string;
  imageAlt?: string;
  brandCode?: string;
}

export interface BrandListProps extends IComponentBrandsList {
  brands: IBrand[];
}

export const BrandList = (props: BrandListProps) => {
  const excludeList =
    props?.exclude
      ?.split(',')
      ?.filter((item) => item)
      ?.map((item) => item.toLowerCase().trim()) ?? [];

  return (
    <div className={twMerge('relative min-h-[6.5rem] mb-5 text-center', props.className)}>
      {!!props.title && <h1 className="text-3xl sm:text-3xl text-sage-700 uppercase font-bold mb-5">{props.title}</h1>}
      {!!props.content && <RichText content={props.content} className="md:text-lg lg:text-xl text-sage-700" />}
      <div className="hidden md:block">
        <div
          className={
            'grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 justify-center justify-items-center gap-x-8 gap-y-2'
          }
        >
          {props.brands.map((brand, index) => {
            if (excludeList.includes(brand?.brandCode?.toLowerCase() ?? '')) return;
            return (
              <div key={index} className="flex items-center justify-items-center hover:border-transparent">
                <a className="self-center" href={brand.url.startsWith('/') ? brand.url : `/${brand.url}`}>
                  <Media
                    position={ComponentPosition.center}
                    imageUrl={brand.imageUrl}
                    imageAltTag={brand.imageAlt}
                    className="flex-shrink-0 grayscale opacity-70 hover:opacity-100 hover:drop-shadow hover:grayscale-0 w-32 py-3 mx-auto"
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div className="block md:hidden">
        <Menu as="div" className="relative mt-3 inline-block text-left z-10 w-5/6">
          <div>
            <MenuButton
              className={
                'inline-flex justify-center w-full rounded-sm border border-sage-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-sage-700 hover:bg-sage-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-sage-100 focus:ring-sage-200'
              }
            >
              Manufacturer / Brand
              <FontAwesomeIcon icon={faChevronDown} className="-mr-1 ml-2 h-5 w-50" aria-hidden="true" />
            </MenuButton>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              className={
                'origin-top-right mt-2 w-full rounded-sm shadow-md bg-white ring-1 ring-sage-800 ring-opacity-5 focus:outline-none'
              }
            >
              <div className="py-1">
                {props.brands.map((brand, index) => {
                  return (
                    <MenuItem key={index}>
                      {({ active }) => (
                        <a
                          key={index}
                          className={`${
                            active ? 'self-center bg-gray-100 text-gray-900' : 'self-center text-gray-700'
                          } block px-4 py-2 text-sm`}
                          href={brand.url.startsWith('/') ? brand.url : `/${brand.url}`}
                        >
                          {brand.title}
                        </a>
                      )}
                    </MenuItem>
                  );
                })}
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
