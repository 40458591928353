import { IFilterListProps } from '@lamesarv-sdk/components';
import { FilterCheckboxAdvanced } from '@lamesarv-sdk/inventory';
import { getFacetCode } from '@lamesarv-sdk/tools';
import { IMetaData } from '@lamesarv-sdk/types';

interface IFilterCheckboxBodiesProps extends IFilterListProps {
  metadata: IMetaData;
}

const CUSTOM_SORT = ['class-a', 'class-b', 'class-c', '5th-wheel', 'travel-trailer', 'toy-hauler'];

export const FilterCheckboxBodies = (props: IFilterCheckboxBodiesProps) => {
  const customItems = props.metadata.pagesBodyData
    ? props.metadata.pagesBodyData
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .filter((item) => !item.filters)
        .map((item) => {
          return {
            dataValue: item.displayText || item.title,
            urlValue: getFacetCode(item.title),
            facetValue: [item.displayText || item.title],
            count: 0,
          };
        })
    : [];

  const prefixAltMap: Record<string, string> = {};
  props.metadata.pagesBodyData?.forEach((item) => {
    if (item.altText) prefixAltMap[getFacetCode(item.title)] = item.altText;
  });

  return (
    <FilterCheckboxAdvanced
      {...props}
      title={props.title as string}
      customItems={customItems}
      prefixMap={props.metadata.bodyImages}
      prefixAltMap={prefixAltMap}
      displaySearch={false}
      displayShowMore={false}
      customSort={(a, b) => {
        const aIndex = CUSTOM_SORT.indexOf(a.urlValue);
        const bIndex = CUSTOM_SORT.indexOf(b.urlValue);

        return aIndex - bIndex;
      }}
    />
  );
};
