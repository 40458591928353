'use client';

import { useEffect, useState } from 'react';

import { InventoryMobileFilters } from '@/components/Inventory/InventoryMobileFilters';
import { mobileFiltersClass } from '@/constants/inventory';
import { IMetaData } from '@lamesarv-sdk/types';

interface IConditionalMobileFiltersProps {
  metadata: IMetaData;
}

export const ConditionalMobileFilters = ({ metadata }: IConditionalMobileFiltersProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const hasMobileFilters = document.getElementsByClassName(mobileFiltersClass).length > 0;

    if (!hasMobileFilters) setShow(true);
  }, []);

  if (!show) return null;

  return (
    <div className="md:hidden px-3 mb-4">
      <InventoryMobileFilters metadata={metadata} />
    </div>
  );
};
