import { useContext } from 'react';

import { InventoryContext } from '@/contexts/InventoryContext';
import { IFilterListProps } from '@lamesarv-sdk/components';
import { FilterCheckboxAdvanced } from '@lamesarv-sdk/inventory';

export const FilterCheckboxEquipment = (props: IFilterListProps) => {
  const { context } = useContext(InventoryContext);

  const customItems = Object.values(context.inventoryFiltersCustom?.[props.attribute] || {});

  return (
    <FilterCheckboxAdvanced
      {...props}
      title={props.title as string}
      customItems={customItems}
      displayMin={100}
      displayShowMore={false}
    />
  );
};
