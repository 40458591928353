'use client';
import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBodyRoute } from '@lamesarv-sdk/tools';
import { IBody, IComponentSliderBodyTypes } from '@lamesarv-sdk/types';

interface ISliderArrowProps {
  className?: string;
  onClick?: () => void;
}

const SliderPrevArrow = ({ className, onClick }: ISliderArrowProps) => {
  return (
    <FontAwesomeIcon
      icon={faChevronLeft}
      className={twMerge(
        'absolute left-0 top-1/2 -translate-y-1/2 cursor-pointer h-6 py-3 px-1 bg-white bg-opacity-80 rounded-r-md text-neutral-500 hover:text-neutral-400 z-10 shadow-md',
        className,
      )}
      onClick={onClick}
    />
  );
};

const SliderNextArrow = ({ className, onClick }: ISliderArrowProps) => {
  return (
    <FontAwesomeIcon
      icon={faChevronRight}
      className={twMerge(
        'absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer h-6 py-3 px-1 bg-white bg-opacity-80 rounded-l-md text-neutral-500 hover:text-neutral-400 z-10 shadow-md',
        className,
      )}
      onClick={onClick}
    />
  );
};

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 8,
  nextArrow: <SliderNextArrow />,
  prevArrow: <SliderPrevArrow />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
  ],
};

const BodyItem = (props: { useSearch: boolean; page: IBody }) => {
  const href = getBodyRoute(props.page);

  return (
    <div className="h-20 my-3 relative">
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 my-0 w-24 md:w-28 cursor-pointer hover:opacity-50">
        <a href={href} className="outline-none">
          <img className="hover:opacity-70" src={props.page.imageUrl} alt={props.page.altText} />
          <div className="uppercase font-bold text-xs mt-1 text-center hover:opacity-50">
            {props.page.displayText || props.page.title}
          </div>
        </a>
      </div>
    </div>
  );
};

interface ISliderBodyProps extends IComponentSliderBodyTypes {
  items: IBody[];
}

const CUSTOM_MOBILE_SORT: { title: string; searchFor: string[] }[] = [
  {
    title: 'Class A',
    searchFor: ['Class A'],
  },
  {
    title: 'Class B',
    searchFor: ['Class B'],
  },
  {
    title: 'Class C',
    searchFor: ['Class C'],
  },
  {
    title: 'Pop Top',
    searchFor: ['Pop Top'],
  },
  {
    title: 'Towables',
    searchFor: ['Travel Trailer', '5th Wheel', 'Toy Hauler'],
  },
];

export const SliderBody = (props: ISliderBodyProps) => {
  const customMobileItems = CUSTOM_MOBILE_SORT.map((item) => {
    const page = props.items.find((p) => item.searchFor.some((s) => p.title.includes(s)));

    if (!page) return null;

    return {
      ...page,
      displayText: item.title,
    };
  });

  return (
    <>
      <div
        className={twMerge(
          `w-full min-h-[6.5rem] bg-sage-100 ${props.bottomSpace && 'mb-5'} hidden md:block`,
          props.className,
        )}
      >
        <Slider {...settings}>
          {props.items.map((item, index) => {
            return <BodyItem key={index} useSearch={!!props.useSearch} page={item} />;
          })}
        </Slider>
      </div>
      <div className={twMerge('md:hidden', props.bottomSpace && 'mb-5', props.className)}>
        <Slider {...settings}>
          {customMobileItems.map(
            (item, index) => item && <BodyItem key={index} useSearch={!!props.useSearch} page={item} />,
          )}
        </Slider>
      </div>
    </>
  );
};
