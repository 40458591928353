'use client';

import Image from 'next/image';
import { useRef } from 'react';
import Slider from 'react-slick';
import { twMerge } from 'tailwind-merge';

import { faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IComponentHero, IMetaData } from '@lamesarv-sdk/types';

import { HeroForm } from './HeroForm';

const imagePositionsClasses: Record<string, string> = {
  left: 'object-left',
  center: 'object-center',
  right: 'object-right',
};

interface IHeroProps extends IComponentHero {
  metadata: IMetaData;
}

export const Hero = (props: IHeroProps) => {
  const sliderRef = useRef<Slider>(null);

  const settings = {
    arrows: false,
    dots: props.displayDots,
    infinite: true,
    autoplay: true,
    speed: props.transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    vertical: props.transitionVertical,
    fade: props.transitionFade,
    dotsClass: 'absolute right-12 bottom-6 !flex flex-col md:flex-row gap-4 [&>li.slick-active_figure]:bg-black',
    customPaging: () => (
      <div
        className={
          'w-4 h-4 bg-white rounded-full cursor-pointer hover:bg-gray-300 border-2 border-black flex items-center justify-center'
        }
      >
        <figure className="w-2 h-2 bg-transparent rounded-full mx-auto my-auto" />
      </div>
    ),
  };

  const handleLinkClick = (url: string): void => {
    if (url.startsWith('#')) {
      document.getElementById(url.slice(1))?.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      window.location.href = url;
    }
  };

  return (
    <div className={twMerge('relative w-full -mb-2', props.className)}>
      <Slider {...settings} ref={sliderRef}>
        {props.images?.map((image, index) => {
          return (
            <div key={index} className="w-full h-[400px] md:h-[525px] relative">
              <Image
                layout="fill"
                src={image.imageUrl ?? ''}
                alt=""
                className={twMerge(
                  'object-cover object-center object-',
                  (image.position && imagePositionsClasses[image.position]) || image.position,
                )}
              />

              {(image.overlayText?.text || image.button) && (
                <div className="flex flex-col items-start absolute top-20 left-[31rem] right-20 gap-8">
                  {image.overlayText?.text && (
                    <span
                      className={twMerge(
                        'text-white text-6xl leading-tight font-bold drop-shadow-md',
                        image.overlayText.className,
                      )}
                    >
                      {image.overlayText.text}
                    </span>
                  )}

                  {image.button && (
                    <a
                      href={image.button.link}
                      onClick={
                        image.button.link
                          ? (e) => {
                              e.preventDefault();
                              handleLinkClick(image.button!.link!);
                            }
                          : undefined
                      }
                      className={twMerge(
                        'block text-center bg-black text-white py-4 px-12 rounded-sm uppercase',
                        image.button.className,
                      )}
                    >
                      {image.button.text}
                    </a>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </Slider>
      <HeroForm metadata={props.metadata} />
      {!!props.urls && (
        <div className="right-0 z-10 flex flex-col justify-end md:absolute top-2 lg:top-16">
          {props.urls.map((link, index) => {
            return (
              <a
                key={index}
                className={
                  'flex flex-row pr-1 pl-3 md:mb-2 mb-px text-white text-sm font-semibold leading-10 bg-blue-800 uppercase opacity-90 hover:opacity-80 hover:bg-sage-800'
                }
                href={link.url}
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(link.url);
                }}
              >
                <span className="grow">{link.title}</span>
                <FontAwesomeIcon
                  icon={faChevronsRight}
                  className="shrink h-5 w-5 mt-3 ml-4 block'} aria-hidden={'true"
                />
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};
