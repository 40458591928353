'use client';

import { BroadcastChannel } from 'broadcast-channel';
import { SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { InventoryItem } from '@/components/Inventory/InventoryItem';
import { InventoryContext } from '@/contexts/InventoryContext';
import { getFavoritesChannel } from '@/tools/broadcast';
import { IMessageFavorites, MessageFavoritesType } from '@/types/inventory';
import { faHeart, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonType, Grid, LaMesaRecVanLogo, Pagination } from '@lamesarv-sdk/components';
import { SearchParamAction, useCustomSearchParams } from '@lamesarv-sdk/hooks';
import { useGetInventoryItems } from '@lamesarv-sdk/inventory';
import { getMultipleFilter } from '@lamesarv-sdk/tools';
import {
  IBasicInventory,
  IComponentGridFavorites,
  IMinimalInventory,
  InventoryField,
  ItemsReducerActionType,
  PropsWithClasses,
} from '@lamesarv-sdk/types';

interface IMinimalInventoryExtended extends IMinimalInventory {
  _notAvailable?: boolean;
}

interface IRefinePaginationProps {
  radix: number;
  nbHits: number;
  nbPages: number;
}

const RefinePagination = (props: IRefinePaginationProps) => {
  const { context, dispatch } = useContext(InventoryContext);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { setSearchParam } = useCustomSearchParams();

  const perPage = context.inventoryMetadata?.perPage ? Number(context.inventoryMetadata?.perPage) : 12;

  const callback = (page: number) => {
    setSearchParam('page', String(page + 1));
    dispatch({
      type: ItemsReducerActionType.updateMetadata,
      payload: {
        ...context.inventoryMetadata,
        currentPage: page + 1,
      },
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (context.inventoryMetadata?.currentPage === undefined) return;
    setCurrentPage(Number(context.inventoryMetadata?.currentPage) - 1);
  }, [context.inventoryMetadata?.currentPage]);

  const classesBase =
    'flex flex-row items-center py-1 px-2.5 text-sm border border-gray-100 cursor-pointer uppercase text-sage-700';
  const classesNormal = 'bg-gradient-to-b from-white to-gray-50 hover:from-white hover:to-gray-100';
  const classesDisabled = 'bg-gradient-to-b from-white to-gray-50 cursor-not-allowed';
  const classesSelected = 'bg-gradient-to-b from-gray-100 to-white hover:from-gray-100 hover:to-white';

  return (
    <Pagination
      totalItems={props.nbHits}
      pageSize={perPage}
      radix={props.radix}
      callback={callback}
      currentPage={currentPage}
      classesBase={classesBase}
      classesNormal={classesNormal}
      classesDisabled={classesDisabled}
      classesSelected={classesSelected}
    />
  );
};

interface IRefinePerPageProps extends PropsWithClasses {
  defaultValue: number;
}

const RefinePerPage = (props: IRefinePerPageProps) => {
  const { context, dispatch } = useContext(InventoryContext);
  const { updateSearchParams } = useCustomSearchParams();

  const items = [
    { label: '6 items', value: 6 },
    { label: '12 items', value: 12 },
    { label: '24 items', value: 24 },
    { label: '48 items', value: 48 },
  ];

  const handleChange = (e: SyntheticEvent) => {
    const value = parseInt((e.target as HTMLInputElement).value, 10);

    updateSearchParams([
      {
        action: SearchParamAction.remove,
        key: 'page',
      },
      {
        action: SearchParamAction.add,
        key: 'perPage',
        value: String(value),
      },
    ]);

    dispatch({
      type: ItemsReducerActionType.updateMetadata,
      payload: {
        ...context.inventoryMetadata,
        perPage: value,
        currentPage: 1,
      },
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const perPage = useMemo(() => {
    return context.inventoryMetadata?.perPage || props.defaultValue;
  }, [context.inventoryMetadata]);

  return (
    <select
      className={`border border-gray-100 ${props.className ?? ''}`}
      value={perPage}
      onChange={handleChange}
      data-testid="select-per-page"
    >
      {items.map((item, index) => {
        return (
          <option key={index} value={String(item.value)}>
            {item.label}
          </option>
        );
      })}
    </select>
  );
};

export const GridFavorites = (props: IComponentGridFavorites) => {
  const { context, dispatch, favoriteUnselectInventory } = useContext(InventoryContext);
  const { fetchItems } = useGetInventoryItems({
    skipFetch: true,
  });
  const { searchParams, setSearchParam } = useCustomSearchParams();
  const [items, setItems] = useState<IMinimalInventoryExtended[]>([]);
  const [itemsMetadata, setItemsMetadata] = useState<IRefinePaginationProps>({
    radix: 2,
    nbHits: 0,
    nbPages: 0,
  });
  const [itemsUpdated, setItemsUpdated] = useState<boolean>(false);
  const channelFavorites = useRef<BroadcastChannel>();

  const updateItems = (itemsBase: IBasicInventory[], itemsFresh: IBasicInventory[]): IMinimalInventoryExtended[] => {
    const itemsNew: IMinimalInventoryExtended[] = [];

    for (const item of itemsBase) {
      const itemFound = itemsFresh.find((itemFresh) => itemFresh[InventoryField.id] === item[InventoryField.id]);
      if (itemFound) {
        itemsNew.push(itemFound);
      } else {
        (item as IMinimalInventoryExtended)._notAvailable = true;
        itemsNew.push(item);
      }
    }

    return itemsNew;
  };

  const getFreshItems = async (itemsBase: IBasicInventory[]): Promise<IBasicInventory[]> => {
    const itemsFilters = getMultipleFilter(
      InventoryField.stockNumber,
      itemsBase.map((item) => item[InventoryField.id]),
    );
    const itemsConfirmed = await fetchItems({
      filters: itemsFilters,
      currentPage: 1,
      perPage: 250,
    });

    return itemsConfirmed.hits.map((item) => item.document);
  };

  const refreshSelection = async () => {
    const inventoryFavoritesData = localStorage.getItem('inventoryFavorites');

    if (inventoryFavoritesData) {
      const inventoryFavorites = JSON.parse(inventoryFavoritesData);
      const inventoryFreshItems = await getFreshItems(inventoryFavorites);
      const inventoryUpdated = updateItems(inventoryFavorites, inventoryFreshItems);

      dispatch({
        type: ItemsReducerActionType.updateFavorites,
        payload: inventoryUpdated,
      });
    }

    setItemsUpdated(true);
  };

  const getItems = async (page: number, perPage: number) => {
    const itemsBase = context?.inventoryFavorites?.slice(page * perPage, page * perPage + perPage) ?? [];

    if (!itemsBase.length && context?.inventoryFavorites?.length) {
      const newPage = Math.ceil(context?.inventoryFavorites?.length / perPage);
      setSearchParam('page', String(newPage));
      dispatch({
        type: ItemsReducerActionType.updateMetadata,
        payload: {
          ...context.inventoryMetadata,
          currentPage: newPage,
        },
      });
      return;
    }

    setItems(itemsBase);
    setItemsMetadata({
      ...itemsMetadata,
      nbHits: context?.inventoryFavorites?.length ?? 0,
      nbPages: Math.ceil(context?.inventoryFavorites?.length ?? 0 / perPage),
    });
  };

  const handleRemoveFavorite = (id: string) => {
    const newFavorites = favoriteUnselectInventory(id, true);
    if (channelFavorites.current && !channelFavorites.current.isClosed) {
      channelFavorites.current.postMessage({
        type: MessageFavoritesType.favoriteUnselectInventory,
        id,
        quantity: newFavorites.length,
      } as IMessageFavorites);
    }
  };

  useEffect(() => {
    if (context?.inventoryFavorites && context?.inventoryMetadata && itemsUpdated) {
      getItems(context.inventoryMetadata.currentPage - 1, context.inventoryMetadata.perPage);
    }
  }, [context?.inventoryFavorites, context?.inventoryMetadata, itemsUpdated]);

  useEffect(() => {
    if (!channelFavorites.current || channelFavorites.current.isClosed) {
      channelFavorites.current = getFavoritesChannel();
    }

    dispatch({
      type: ItemsReducerActionType.updateMetadata,
      payload: {
        ...(context.inventoryMetadata ?? {}),
        currentPage: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        perPage: searchParams.get('perPage') ? Number(searchParams.get('perPage')) : 12,
      },
    });

    if (channelFavorites.current && !channelFavorites.current.isClosed) {
      channelFavorites.current.onmessage = async () => {
        refreshSelection();
      };
    }

    refreshSelection();

    return () => {
      if (channelFavorites.current && !channelFavorites.current.isClosed) {
        channelFavorites.current.close();
      }
    };
  }, []);

  const isLoading = context?.inventoryMetadata === undefined || !itemsUpdated;

  return (
    <div className={twMerge('relative', props.className)}>
      {/* Actions */}
      {!!items.length && (
        <div className="flex flex-col gap-2 xl:flex-row mb-3 items-start">
          <div className="w-full flex-grow mb-3 md:mb-0 md:w-auto">
            <RefinePagination {...itemsMetadata} />
          </div>
          <div className="flex flex-col flex-shrink w-full md:w-auto md:flex-row">
            <div className="flex-shrink mb-3 md:mb-0 md:mr-1 ">
              <RefinePerPage defaultValue={12} className="w-full md:w-auto uppercase text-xs text-sage-800" />
            </div>
          </div>
        </div>
      )}
      {/* Items */}
      {!!items.length && (
        <Grid
          items={items.map((item, index) => {
            return (
              <div className="flex flex-col h-full">
                <div className="w-full flex flex-row justify-end px-2.5" data-id={item[InventoryField.id]}>
                  <Button
                    type={ButtonType.outline}
                    icon={<FontAwesomeIcon icon={faXmark} className="h-4" />}
                    title="Remove Item"
                    className="flex flex-row items-center gap-1 text-xs text-red-500 border-red-200 border-b-0 py-1 px-3 rounded-none rounded-t-md"
                    onClick={() => handleRemoveFavorite(item[InventoryField.id])}
                  />
                </div>
                <InventoryItem
                  key={index}
                  item={item as IBasicInventory}
                  customImage={item.image}
                  sold={item._notAvailable}
                />
              </div>
            );
          })}
          baseCols={props.baseCols ?? 1}
          smCols={props.smCols ?? 2}
          mdCols={props.mdCols ?? 2}
          lgCols={props.lgCols ?? 3}
          xlCols={props.xlCols ?? 4}
          gap={props.gap ?? 6}
        />
      )}
      {/* Items not found */}
      {!isLoading && !items.length && (
        <div className="flex flex-col gap-2 text-sage-400">
          <div className="text-md">No favorite items found</div>
          <div className="flex flex-row items-center gap-2">
            <span>
              You can add RVs items to your favorite list using the favorite button found on each details page:
            </span>
            <a
              href="/search"
              className="flex flex-row gap-1 items-center justify-between border border-neutral-300 px-3 py-2 cursor-pointer leading-none group hover:bg-neutral-100"
            >
              <span className="text-xs uppercase group-hover:text-red-500">Favorite</span>
              <FontAwesomeIcon icon={faHeart} className="h-4 group-hover:text-red-500" />
            </a>
          </div>
        </div>
      )}
      {/* Loading */}
      {isLoading && (
        <div className="flex flex-row justify-center pt-10 animate-pulse">
          <LaMesaRecVanLogo className="h-20" />
        </div>
      )}
      {/* Actions */}
      {!!items.length && (
        <div className="flex flex-col gap-2 xl:flex-row mb-3 items-start">
          <div className="w-full flex-grow mb-3 md:mb-0 md:w-auto">
            <RefinePagination {...itemsMetadata} />
          </div>
          <div className="flex flex-col flex-shrink w-full md:w-auto md:flex-row">
            <div className="flex-shrink mb-3 md:mb-0 md:mr-1 ">
              <RefinePerPage defaultValue={12} className="w-full md:w-auto uppercase text-xs text-sage-800" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
