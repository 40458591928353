'use client';
import { twMerge } from 'tailwind-merge';

import { defaultImageUrl } from '@/constants/inventory';
import { GridInventory } from '@lamesarv-sdk/inventory';
import { IBasicInventory, IComponentGridInventory, IMetaData, InventoryField } from '@lamesarv-sdk/types';

import { InventoryItem } from './InventoryItem';

export const inventoryGridClassName = 'gap-x-2 min-[990px]:!grid-cols-2 min-[1380px]:!grid-cols-3';

interface InventoryGridProps extends IComponentGridInventory {
  metadata: IMetaData;
}

export const InventoryGrid = (props: InventoryGridProps) => (
  <GridInventory
    {...props}
    sort={`${InventoryField.order}:asc`}
    defaultImageUrl={defaultImageUrl}
    CustomInventoryItem={(item: IBasicInventory) => <InventoryItem item={item} />}
    className={twMerge(inventoryGridClassName, props.className)}
  />
);
